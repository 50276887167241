<template>
  <div>
    <a-page-header class="header_title" title="Orders" />
    <div class="box_content">
      <a-descriptions title="Imcome Overview" :column="4">
        <a-descriptions-item label="To Release" :span="2">
          <!-- <p>From:1 jul - Now</p> -->
          <!-- <p>Payment Service:</p> -->
        </a-descriptions-item>
        <a-descriptions-item label="Release" :span="2">
          <a @click="history">View past bills</a>
        </a-descriptions-item>

        <a-descriptions-item label="From" :span="2">
          {{ Overview.ToRelease.From }}
        </a-descriptions-item>
        <a-descriptions-item label="This Cycle" :span="2">
          {{ Overview.Release.ThisCycle }}
        </a-descriptions-item>

        <a-descriptions-item label="Payment Service" :span="2">
          {{ Overview.ToRelease.PaymentService }}
        </a-descriptions-item>
        <a-descriptions-item label="" :span="2">
          <a @click="showModal2()">set up</a>
        </a-descriptions-item>
      </a-descriptions>

      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="To Release">
          <a-table
            :columns="columns"
            :data-source="toRelease_data"
            :rowKey="(record, index) => index"
            :pagination="pagination"
            :loading="loading"
            @change="toRelease_handleTableChange"
            size="middle"
          >
            <span slot="Products" slot-scope="text, data" class="td_black">
              <img
                v-bind:src="data.img"
                style="width: 50px; float: left; margin-right: 5px"
              />
              <p>{{ data.Products }}</p>
            </span>
            <span slot="Status" slot-scope="text, data">
              <a-tag v-if="data.Status == 1">Product preparation</a-tag>
              <a-tag v-if="data.Status == 2">Not signed</a-tag>
              <a-tag v-if="data.Status == 3">Sign for</a-tag>
            </span>
            <span slot="action" slot-scope="text, data">
              <button
                class="btn"
                @click="showModal(data.id, data.Status)"
                v-if="data.Status == 1"
              >
                View details
              </button>
              <button
                class="btn"
                @click="showModal(data.id, data.Status)"
                v-if="data.Status == 2"
              >
                View details
              </button>
            </span>
          </a-table>
        </a-tab-pane>

        <a-tab-pane key="2" tab="Release" force-render>
          <a-table
            :columns="columns"
            :data-source="release_data"
            :rowKey="(record, index) => index"
            :pagination="pagination"
            :loading="loading"
            @change="release_handleTableChange"
            size="middle"
          >
            <span slot="Products" slot-scope="text, data" class="td_black">
              <img
                v-bind:src="data.img"
                style="width: 50px; float: left; margin-right: 5px"
              />
              <p>{{ data.Products }}</p>
            </span>

            <span slot="Status" slot-scope="text, data">
              <a-tag v-if="data.Status == 1">Product preparation</a-tag>
              <a-tag v-if="data.Status == 2">Not signed</a-tag>
              <a-tag v-if="data.Status == 3">Sign for</a-tag>
            </span>

            <span slot="action" slot-scope="text, data">
              <button
                class="btn"
                @click="showModal(data.Order, data.Status)"
                v-if="data.Status == 1"
              >
                View details
              </button>
              <button
                class="btn"
                @click="showModal(data.Order, data.Status)"
                v-if="data.Status == 2"
              >
                View details
              </button>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    
    <a-modal v-model="visible" title="" on-ok="handleOk">
      <template slot="footer">
        <a-form layout="inline" :form="form">
          <a-form-item label="">
              <a-input
                v-decorator="[
                  'str1',
                  { rules: [{ required: true, message: 'Please input!' }] },
                ]"
                placeholder=""
              >
              </a-input>
          </a-form-item>
          <a-form-item label="">
            <a-input
              v-decorator="[
                'str2',
                { rules: [{ required: true, message: 'Please input!' }] },
              ]"
              placeholder=""
            >
            </a-input>
          </a-form-item>
        </a-form>
        <a-button key="submit" type="primary" :loading="loading2" @click="handleOk">
         Submit
        </a-button>
      </template>
      <!-- <a-form layout="inline" :form="form">
          <a-form-item label="">
              <a-input
                v-decorator="[
                  'str1',
                  { rules: [{ required: true, message: 'Please input!' }] },
                ]"
                placeholder=""
              >
              </a-input>
          </a-form-item>
          <a-form-item label="">
            <a-input
              v-decorator="[
                'str2',
                { rules: [{ required: true, message: 'Please input!' }] },
              ]"
              placeholder=""
            >
            </a-input>
          </a-form-item>
        </a-form> -->
      </a-modal>

  </div>
</template>

<script>
import {
  getOrders,
  getOrderDetail,
  getOverview,
} from "@/network/order2Reuqest";
// const Status = {
//   '1':'Product preparation',
//   '2':'Not signed',
//   '3':'Sign for',
// };
const columns = [
  {
    title: "Product(s)",
    key: "Products",
    dataIndex: "Products",
    scopedSlots: { customRender: "Products" },
  },
  {
    title: "Total Price",
    dataIndex: "TotalPrice",
    key: "TotalPrice",
  },
  {
    title: "Status",
    key: "Status",
    scopedSlots: { customRender: "Status" },
  },
  {
    title: "Action",
    dataIndex: "Action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "NodeprojectOrders",
  components: {},
  directives: {},
  data() {
    return {
      visible: false,
      Overview: {
        ToRelease: {
          From: "",
          PaymentService: "",
        },
        Release: {
          ThisCycle: "",
        },
      },
      tab_key: 1,
      pagination: {},
      release_page: 1,
      release_data: [],
      release_total_rows: 0,
      toRelease_page: 1,
      toRelease_data: [],
      toRelease_total_rows: 0,
      columns,
      loading: false,
      visible: false,
      loading2:false,
    };
  },
  created() {
    this.getList(1);
    this.getList2();
  },
  mounted() {},
  methods: {
      handleOk(e) {
        this.form.validateFields((err, values) => {
        if (!err) {
          this.loading2 = true;
          console.info('success');
          console.log(values);
          setLogisticsInformation().then(res=>{
            this.visible = false;
            this.loading2 = false;
          });
        }})
    },
    getList2() {
      getOverview().then((res) => {
        if (res.header.code == 200) {
          this.Overview = res.body;
        }
      });
    },
    toRelease_handleTableChange(pagination, filters, sorter) {
      this.toRelease_page = pagination.current;
      getList(this.toRelease_page);
    },
    release_handleTableChange(pagination, filters, sorter) {
      this.release_page = pagination.current;
      getList(this.release_page);
    },
    getList(page) {
      this.loading = true;
      getOrders(page, this.tab_key).then((res) => {
        this.loading = false;
        if (res.header.code == 200) {
          if (this.tab_key == 1) {
            this.toRelease_total_rows = res.body.total_rows;
            this.toRelease_data = res.body.orders;
          } else {
            this.release_total_rows = res.body.total_rows;
            this.release_data = res.body.orders;
          }
        }
      });
    },
    showModal(Order, Status) {
      // this.visible = true;
      this.$router.push({
        path: `/orders2/status`,
        query: { Order: Order, Status: Status },
      });
    },
    showModal2(Product) {
      // this.visible = true;
    },
    handleCancel(e) {
      this.visible = false;
    },
    history() {
      this.$router.push({ path: `/orders2/history` });
    },
    callback(key) {
      // console.log(key);
      this.tab_key = key;
      if (key == 1) {
        this.getList(this.toRelease_page);
      } else {
        this.getList(this.release_page);
      }
    },
  },
};
</script>

<style  scoped>
</style>